"use client";

import { useEffect } from "react";
import { useSearchParams, usePathname } from "next/navigation";

import { setStorage } from "@/lib/storage";

export default function AdParamsInit() {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    const fbclid = searchParams.get("fbclid");
    const gclid = searchParams.get("gclid");
    const msclkid = searchParams.get("msclkid");

    if (fbclid) setStorage("fbclid", fbclid);
    if (gclid) setStorage("gclid", gclid);
    if (msclkid) setStorage("msclkid", msclkid);
  }, [searchParams, pathname]);

  return null;
}
