"use client";

import { initAdjustSDK } from "@/core/services/adjust";

initAdjustSDK();

export default function AdjustInit() {
  // Render nothing - this component is only included so that the init code above will run client-side
  return null;
}
