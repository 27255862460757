import(/* webpackMode: "eager", webpackExports: ["Root","Indicator","displayName"] */ "/opt/glo-web-app/node_modules/@radix-ui/react-progress/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollAreaScrollbar","ScrollAreaThumb","Root","Viewport","Corner","displayName"] */ "/opt/glo-web-app/node_modules/@radix-ui/react-scroll-area/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/glo-web-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTheme"] */ "/opt/glo-web-app/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/Graphik-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../public/fonts/Graphik-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/Graphik-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/Graphik-Semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../public/fonts/Graphik-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../public/fonts/Graphik-Black.woff2\",\"weight\":\"900\",\"style\":\"normal\"}],\"variable\":\"--font-sanserif\"}],\"variableName\":\"fontGraphik\"}");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/fonts/AmerigoBT-Regular.woff2\",\"variable\":\"--font-sans\",\"display\":\"swap\"}],\"variableName\":\"fontAmerigo\"}");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/app/layout.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/glo-web-app/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Drawer","Root","Trigger","Portal","Close","Overlay","displayName","Content","Title","Description"] */ "/opt/glo-web-app/node_modules/vaul/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/opt/glo-web-app/src/context/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatContextProvider"] */ "/opt/glo-web-app/src/context/ChatContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/glo-web-app/src/lib/ad-params.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/lib/adjust.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/glo-web-app/src/lib/context/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MonitorInit"] */ "/opt/glo-web-app/src/lib/monitor.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/components/Loading/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["NavBarContent"] */ "/opt/glo-web-app/src/ui/components/NavBar/NavBarContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StickyNavBarContainer"] */ "/opt/glo-web-app/src/ui/components/NavBar/StickyNavBarContainer.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/components/PagingPillButton/PagingPillButton.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/components/ShowMoreOrLess.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPlayer"] */ "/opt/glo-web-app/src/ui/components/VideoPlayer/VideoPlayer.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Avatar.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Dialog.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/DropdownMenu.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Form.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Label.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/NavigationMenu.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/RadioGroup.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Separator.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Slider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/Toggle.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/shadcn/ToggleGroup.tsx");
;
import(/* webpackMode: "eager" */ "/opt/glo-web-app/src/ui/styles/globals.scss");
